import React from 'react'
import { useApi } from '~/api'
import { PageProps } from 'gatsby'
import { Head, Footer, Banner, Feedbacks, Numbers } from '~/components'
import Slider from '@fil1pe/react-slider'
import * as st from '~/assets/styl/About.module.styl'
import { LazyImage } from 'react-lazy-images'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'
import imgTeste from '~/assets/img/img-about-us.jpg'
import imgMobile from '~/assets/img/img-about-us-mobile.jpg'
import imgFamily from '~/assets/img/img-about-us-02.jpg'
import imgFamilyMob from '~/assets/img/img-about-us-02-mobile.jpg'
import imgSlide from '~/assets/img/img-about-us-slide.jpg'
import imgSlideMob from '~/assets/img/img-about-us-slide-mobile.jpg'
import Collaborators from '~/components/Collaborators'

const Sobre = ({ pageContext }: PageProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 550px)' })
  const context = useApi(pageContext, 'page-about')
  const { numbers, testimonials } = context
  const sliderData = [
    {
      img: imgSlide,
      imgMobile: imgSlideMob,
    },
    {
      img: imgSlide,
      imgMobile: imgSlideMob,
    },
  ]
  return (
    <>
      <Head description="Proteção para você viver bem!" />
      <main className={st.core}>
        <Banner
          bigBanner
          title="Sobre a
          Dimas Seguros"
          content="Confira um pouco da nossa
          história e trajetória"
          img={imgTeste}
          mobile={imgMobile}
        />
        <div className={st.about}>
          <section className="container clear-fix">
            <h2 className={st.smallFont}>
              A <strong>Dimas Seguros Corretora de Seguros</strong> nasceu de um
              sonho do Sr. Dimas Arnoldo da Silva, fundador das Empresas Dimas,
              que é uma das maiores referências no mercado automotivo em Santa
              Catarina, com mais de 46 anos de história.
            </h2>
            <p className={st.largeFont}>
              Hoje, a família Dimas é uma grande marca na região, e conta com 11
              empresas, que além de se fazer presente nos serviços de
              automóveis, também atua no campo da construção civil.
            </p>
            <img
              src={imgFamily}
              className={st.desk}
              alt="Pai e mãe brincando com seu filho"
            />
            <figure className={st.mobile}>
              <img src={imgFamilyMob} alt="Pai e mãe brincando com seu filho" />
            </figure>
            <div className={st.flex}>
              <div>
                <p>
                  <strong>Nossas empresas são:</strong> Ford Dimas; Volvo Dimas;
                  Dimas Select; Dimas Auto; Dimas Sempre; WeCharge; WeCharge
                  Shop; Dimas Repasse; Dimas Caminhões; Dimas Construtora e a
                  Dimas Seguros Corretora de Seguros.
                </p>
                <p>
                  Seguindo a os passos do Sr. Dimas, a Dimas Seguros surgiu como{' '}
                  <strong>uma empresa que pensa no futuro</strong>, e que se
                  preocupa com o bem-estar das pessoas. Nossa missão é inovar a
                  cada dia, e oferecer ao cliente um atendimento personalizado,
                  fazendo negócios com transparência e confiança, fidelizando
                  parcerias e <strong>criando um elo duradouro</strong>.
                </p>
              </div>
              <div className={cn(st.borderGreen, st.customBottom)}>
                <p>
                  Desde 2016, o ano de fundação da Dimas Seguros, ajudamos os
                  nossos clientes a encontrarem o melhor Seguro Automóvel, e com
                  o nosso grande crescimento, passamos a oferecer outras
                  modalidades de seguro,{' '}
                  <strong>que trazem mais tranquilidade</strong> para a vida
                  daqueles que escolhem contar conosco.
                </p>
                <p className={st.customMargin}>
                  <strong>
                    Atualmente, a Dimas Seguros Corretora de Seguros oferece:
                  </strong>{' '}
                  Seguro Auto; Seguro Residencial; Seguro de Vida; Seguro
                  Previdência; Seguros Viagem; Seguro Mobile e muito mais!{' '}
                </p>
              </div>
            </div>
            <Slider
              slidesToShow={1}
              slidesToScroll={1}
              className="slider ad green"
            >
              {sliderData.map(({ img, imgMobile }, index) => (
                <div key={index} className="slide">
                  {isMobile ? (
                    <LazyImage
                      src={imgMobile}
                      alt=""
                      className={st.slideMobile}
                      placeholder={({ imageProps, ref }) => (
                        <img ref={ref} src={imgMobile} alt={imageProps.alt} />
                      )}
                      actual={({ imageProps }) => <img {...imageProps} />}
                    />
                  ) : (
                    <LazyImage
                      src={img}
                      alt=""
                      className={st.slideDesk}
                      placeholder={({ imageProps, ref }) => (
                        <img ref={ref} src={img} alt={imageProps.alt} />
                      )}
                      actual={({ imageProps }) => <img {...imageProps} />}
                    />
                  )}
                </div>
              ))}
            </Slider>
            ;
            <div className={st.flex}>
              <div className={st.borderGreen}>
                <p>
                  Desejamos proporcionar a melhor experiência na hora de
                  contratar um seguro, de forma humanizada e esclarecedora,
                  <strong>
                    {' '}
                    garantindo toda a proteção que uma Corretora de Seguros pode
                    promover.
                  </strong>{' '}
                </p>
              </div>
              <div>
                <p className={cn(st.largeFont, st.blueDark, st.customPadding)}>
                  Para garantir esse cuidado exclusivo, venha fazer o seu seguro
                  com a Dimas Seguros e entrar para a história da família Dimas!
                </p>
              </div>
            </div>
          </section>
        </div>
        <Collaborators />
        <Numbers data={numbers} />
        <Feedbacks data={testimonials} />
      </main>
      <Footer />
    </>
  )
}

export default Sobre
