import React from 'react'
import * as st from '~/assets/styl/Collaborators.module.styl'
import collaboratorsData from '../data/collaboratorsData'

const Collaborators = () => {
  return (
    <div className={st.core}>
      <section className={st.container}>
        <h2>Conheça a nossa equipe</h2>
        <ul className={st.containerCards}>
          {collaboratorsData.map((collaborator, index) => (
            <li className={st.card} key={index}>
              <img
                src={collaborator.image}
                alt={collaborator.name}
                width="160px"
                height="160px"
              />
              <span>{collaborator.name}</span>
              <p>{collaborator.role}</p>
            </li>
          ))}
        </ul>
      </section>
    </div>
  )
}

export default Collaborators
