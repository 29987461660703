// extracted by mini-css-extract-plugin
export var about = "About-module--about--nMOVq";
export var blueDark = "About-module--blue-dark--LywHH";
export var borderGreen = "About-module--border-green--TgG87";
export var core = "About-module--core--Fq0Mw";
export var customBottom = "About-module--custom-bottom--gdenB";
export var customMargin = "About-module--custom-margin--oHxzP";
export var customPadding = "About-module--custom-padding--kKcIo";
export var desk = "About-module--desk--AnxyF";
export var flex = "About-module--flex--VcTKu";
export var largeFont = "About-module--large-font--21Sbl";
export var mobile = "About-module--mobile--E2ysn";
export var slideDesk = "About-module--slide-desk--gm0nT";
export var slideMobile = "About-module--slide-mobile--4apFm";
export var smallFont = "About-module--small-font--8rTbZ";